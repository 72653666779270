<template>
  <AccordionRows
    :entities="entities"
    :fields="fieldRows"
    :isPending="currentIsPending"
  />
</template>
<script>
import { computed } from "vue-demi";
import AccordionRows, { entityRow, entityRowField } from "./AccordionRows.vue";
import { useGetHelper } from "../../utils/feathers-pinia/use-get-helpers";
import { useBuffer } from "../../store/buffer.pinia";
import { useBinType } from "../../store/binType.pinia";
import { useInventoryManagementPolicy } from "../../store/inventoryManagementPolicy.pinia";
import { isRegionUS } from "../../utils/variables";

export default {
  name: "BufferAccordion",
  props: {
    node: {
      default: null,
    },
    paneInfo: {
      default: null,
    },
    isPending: {
      default: () => ({}),
    },
  },
  components: {
    AccordionRows,
  },
  setup(props) {
    console.warn("BufferAccordion.vue");
    const bufferStore = useBuffer();
    const binTypeStore = useBinType();
    const inventoryManagementPolicyStore = useInventoryManagementPolicy();

    const bufferId = computed(() => props?.node?.buffer?.id ?? null);
    const { item: bufferResult, isPending: isPendingBuffer } = useGetHelper(
      bufferId,
      bufferStore
    );

    const binTypeId = computed(() => bufferResult?.value?.bin_type_id ?? null);
    const { item: binTypeResult, isPending: isPendingBinType } = useGetHelper(
      binTypeId,
      binTypeStore
    );

    const inventoryManagementPolicyId = computed(
      () => bufferResult?.value?.inventory_management_policy_id ?? null
    );
    const { item: inventoryManagementPolicyResult } = useGetHelper(
      inventoryManagementPolicyId,
      inventoryManagementPolicyStore
    );

    const currentIsPending = computed(() => {
      return {
        ...props.isPending,
        binType: isPendingBinType.value,
        buffer: isPendingBuffer.value,
        partPreSchedule: props.isPending.part,
        inventoryManagementPolicy: props.isPending.part,
      };
    });

    const entities = computed(() => {
      return {
        part: props.node.part,
        binType: binTypeResult.value,
        buffer: bufferResult.value,
        partPreSchedule: props.node?.part?.part_preschedules?.find(
          (item) => item.type === "PreSchedule"
        ),
        inventoryManagementPolicy: inventoryManagementPolicyResult?.value,
      };
    });

    const conditionalFields = computed(() => {
      let fields = [];
      if (entities.value?.buffer?.signal_method === "putaway") {
        fields.push(
          entityRowField("part", "min_stock", { label: "Min Stock" }),
          entityRowField("buffer", "reorder_point", {
            label: "Reorder Point",
          }),
          entityRowField("buffer", "receive_in_pkg_qty", {
            label: "Receive in Package Qty",
            type: "checkbox",
          }),
          entityRowField("buffer", "warehouse_reorder_point", {
            label: "Warehouse Reorder Point",
          }),
          entityRowField("buffer", "qty_per_handling_unit", {
            label: "Qty per Handling Unit",
          })
        );
      } else if (entities.value.buffer?.signal_method === "emove") {
        fields.push(
          !isRegionUS
            ? entityRowField("inventoryManagementPolicy", "description", {
                label: "Policy",
              })
            : null,
          !isRegionUS
            ? entityRowField("inventoryManagementPolicy", "schedule_days", {
                label: "Schedule Buckets",
              })
            : null,
          !isRegionUS
            ? entityRowField("inventoryManagementPolicy", "store_time_days", {
                label: "Store days",
              })
            : null,
          entityRowField("buffer", "qty_per_handling_unit", {
            label: "Qty per Handling Unit",
          }),
          entityRowField("buffer", "qty_handling_units", {
            label: "Qty of Handling Units",
          }),
          entityRowField("buffer", "no_mrp_demand", {
            label: "No MRP Demand",
            type: "checkbox",
          }),
          entityRowField("buffer", "putaway_zone_code'", {
            label: "Putaway Zone Code",
          })
        );
      }
      if (entities.value.partPreSchedule?.type === "PreSchedule") {
        fields.push(
          entityRowField("partPreSchedule", "target_qty", {
            label: "Preschedule Target Qty",
          }),
          entityRowField("partPreSchedule", "on_hand_qty", {
            label: "Preschedule On Hand Qty",
          })
        );
      }
      return fields.filter((item) => item !== null);
    });

    const fieldRows = computed(() => {
      return [
        entityRow("Buffer", [
          entityRowField("buffer", "description", {
            label: "Description",
            colAttributes: {
              cols: 12,
              sm: 6,
              class: "px-2",
            },
          }),
          entityRowField("buffer", "signal_method", {
            label: "Signal Method",
            colAttributes: {
              cols: 12,
              sm: 6,
              class: "px-2",
            },
          }),
          entityRowField("binType", "description", {
            label: "Bin Type Description",
            colAttributes: {
              cols: 12,
              sm: 6,
              class: "px-2",
            },
          }),
          entityRowField("binType", "handling_unit_code", {
            label: "Bin Type Handing Unit",
            colAttributes: {
              cols: 12,
              sm: 6,
              class: "px-2",
            },
          }),
          entityRowField("buffer", "hide_on_picklists", {
            label: "Hide on Picklists",
            type: "checkbox",
          }),
        ]),
        entityRow("conditional fields", conditionalFields.value),
      ];
    });

    return {
      entities,
      fieldRows,
      currentIsPending,
    };
  },
};
</script>
